import { Dropdown } from 'flowbite'
import { Controller } from '@hotwired/stimulus'

import morphdom from 'morphdom'

/**
 * Merges the attributes of two input elements
 *
 * @param {HTMLInputElement} target
 * @param {HTMLInputElement} source
 *
 * @returns {void}
 */
function mergeAttributes (target, source) {
  for (const name of source.getAttributeNames()) {
    const value = source.getAttribute(name)
    target.setAttribute(name, value)
  }
}

export default class extends Controller {
  static targets = ['button', 'menu', 'form']

  #inputDebouncer = null

  connect () {
    // eslint-disable-next-line no-new
    new Dropdown(
      this.menuTarget,
      this.buttonTarget,
      {
        onHide: () => {
          this.formTarget.requestSubmit()
        }
      },
      {
        id: 'dropdownMenu',
        override: true
      }
    )
  }

  submit () {
    this.formTarget.requestSubmit()
  }

  onInput () {
    clearTimeout(this.#inputDebouncer)
    this.#inputDebouncer = setTimeout(() => this.submit(), 300)
  }

  /**
   * A `turbo:before-frame-render` event handler for this stimulus controller.
   * We use it to morph the DOM from the existing page and the incoming turbo
   * response so that the focused elemens (such as the search input) aren't
   * changed while the user is interacting with them.
   *
   * @see https://turbo.hotwired.dev/handbook/frames#custom-rendering
   *
   * @param {Event} event
   */
  beforeFrameRender (event) {
    event.detail.render = (currentElement, newElement) => {
      morphdom(currentElement, newElement, {
        childrenOnly: true,
        onBeforeElUpdated: function (fromElement, toElement) {
          const focused = document.activeElement
          if (
            fromElement instanceof HTMLInputElement && fromElement === focused
          ) {
            mergeAttributes(fromElement, toElement)
            return false
          } else if (fromElement.isEqualNode(toElement)) {
            return false
          }

          return true
        }
      })
    }
  }
}
