import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dialog']

  show () {
    this.dialogTarget.showModal()
  }

  close () {
    this.dialogTarget.close()
  }

  // Close if the user clicks on the backdrop
  closeIfClickedOutside (event) {
    if (event.target === this.dialogTarget) {
      this.close()
    }
  }
}
